import {useEffect, useState} from 'react';
import { throttle } from 'lodash';

export const useScroll = ({
  el,
  throttleRate = 200,
}) => {
  
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const onScrollUpdate = (evt) => {
    setScrollX(el.scrollX);
    setScrollY(el.scrollY);
  }

  useEffect(() => {

    if( el ) {
      el.addEventListener('scroll', throttle(onScrollUpdate, throttleRate), false);
    }

    return () => {
      el.removeEventListener('scroll', throttle, false);
    }
  }, []);

  return {
    x: scrollX,
    y: scrollY
  }
}