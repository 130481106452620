import React from "react";

import Logo from "../../images/logo-adapted--ko.svg";

const LogoImg = () => {

  // 771 x 184
  return (
    <Logo className="fill-current text-white h-full w-auto" />
  );
}

export default LogoImg;