import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { throttle } from 'lodash';

import LogoImg from '../logo/LogoImg';
import { useScroll } from '../../hooks/useScroll';

import * as styles from './Header.module.css';


const HeaderNavLink = ({to, children, ...props}) => {
  return (
    <Link to={to} className={styles.link} activeClassName={styles.active} {...props}>{children}</Link>
  );
}

const MobileHeader = ({docked}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const classes = clsx(
    styles.header,
    [menuIsOpen && styles.opened],
    [docked && styles.docked]
  );

  const toggleMenu = (evt) => {
    setMenuIsOpen(!menuIsOpen);
  }

  return (

    <header className={classes}>
      <div className={styles.bar}>
        <div className={styles.brand}>
          <Link to="/" onClick={() => setMenuIsOpen(false)} style={{display: 'block', height: '35px'}}>
            <LogoImg />
          </Link>
        </div>
        <button className={styles.toggle} onClick={toggleMenu}></button>
      </div>
      <div className={styles.nav}>
        <ul className={styles.menu}>
          <li className={styles.item}>
            <HeaderNavLink to="/capabilities" onClick={() => setMenuIsOpen(false)}>Capabilities</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/case-studies" onClick={() => setMenuIsOpen(false)}>Case Studies</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/our-story" onClick={() => setMenuIsOpen(false)}>Our Story</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/team" onClick={() => setMenuIsOpen(false)}>Team</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/news" onClick={() => setMenuIsOpen(false)}>News</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/careers" onClick={() => setMenuIsOpen(false)}>Careers</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/contact" onClick={() => setMenuIsOpen(false)}>Contact</HeaderNavLink>
          </li>
        </ul>
      </div>
    </header>
  );
}

const DesktopHeader = ({docked}) => {

  return (
    <header className={`${styles.header} ${docked ? styles.docked : ''}`}>
      <div className={styles.brand}>
        <Link to="/" style={{display: 'block', height: '40px'}}>
          <LogoImg />
        </Link>
        
      </div>
      <div className={styles.nav}>
        <ul className={styles.menu}>
          <li className={styles.item}>
            <HeaderNavLink to="/capabilities">Capabilities</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/case-studies">Case Studies</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/our-story">Our Story</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/team">Team</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/news">News</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/careers">Careers</HeaderNavLink>
          </li>
          <li className={styles.item}>
            <HeaderNavLink to="/contact">Contact</HeaderNavLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

const Header = () => {

  const [isMobile, setIsMobile] = useState(true);
  

  const threshold = isMobile ? 60 : 90;
  const [docked, setDocked] = useState(true);
  const {x, y} = useScroll({el: window});

  const updateResize = (evt) => {
    setIsMobile(window.matchMedia('(min-width: 1024px)').matches === false);
  }

  useEffect(() => {

    if( y > threshold) {
      setDocked(false);
    } else {
      setDocked(true);
    }

  }, [x, y]);
  
  useEffect(() => {
    setIsMobile(window.matchMedia('(min-width: 1024px)').matches === false);

    const resz = throttle(updateResize, 250);

    window.addEventListener('resize', resz);

    return () => {
      window.removeEventListener('resize', resz);
    }
    
  });

  if( isMobile ) {
    return (
      <MobileHeader docked={docked} />
    )
  }

  return (
    <DesktopHeader docked={docked} />
  )
}

export default Header;