import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import Footer from '../footer/Footer';
import Header from '../header/Header';

const Layout = ({location, children}) => {

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${location.pathname}`} />
        { process.env.NODE_ENV === 'production' ? (
          <React.Fragment>
            <meta name='robots' content='index, follow' />
          </React.Fragment>
        ) : (
          <meta name='robots' content='noindex' />
        )}
        <title>Medicus Economics &#8211; Attention. Expertise. With no distractions.</title>
        <link rel='dns-prefetch' href='//www.google.com' />
      </Helmet>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;